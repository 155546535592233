import { amgApi } from "@/service/axios";

class SettingsService {

    async getSetting(body) {
        const data = await amgApi.post('/settings/save-msg-setting', body);
        return data;
    }
}

export default new SettingsService();